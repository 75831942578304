@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
    .socials {
      @apply w-[35px] h-[35px] rounded-[100%] flex justify-center items-center border border-[#1B1C19] hover:bg-[#A8CA55] hover:border-none transition-all duration-500 ease-in-out;
    }
}

.generalbody{
  max-width: 1800px;
  margin: auto;
  overflow-x: hidden;
}

